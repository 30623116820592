import * as React from "react";
import { Grid } from "@material-ui/core";
import { Layout } from "../common/Layout";
import { SEO } from "../components/seo";
import { Heading } from "../components/heading";

function ThankYouPage() {
    return (
        <Layout>
            <SEO title="Contact - Thank You" />

            <section>
                <Grid container spacing={4}>
                    <Grid item xs={12}>
                        <Heading text="Contact" />
                    </Grid>
                    <Grid item xs={12}>
                        <p>Thank you for your submission!</p>
                    </Grid>
                </Grid>
            </section>
        </Layout>
    );
}

export default ThankYouPage;
